import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { injectIntl } from "gatsby-plugin-react-intl"
import { StaticImage } from "gatsby-plugin-image"

const NotFoundPage = ({ intl }) => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="hero-image">
      <div className="hero-image-text">
        <p className="hero-image-title">{intl.formatMessage({ id: "404.title" })}</p>
        <Link to="/" className="button">{intl.formatMessage({ id: "404.button1" })}</Link>
        <Link to="/menu/" className="button">{intl.formatMessage({ id: "404.button2" })}</Link>
      </div>
      <StaticImage className="hero-image__image image-404" src="../images/shop/outside.jpg" alt={intl.formatMessage({ id: "image-outside" })}/>
    </div>
  </Layout>
)

export default injectIntl(NotFoundPage)
